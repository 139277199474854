// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-health-living-phamracy-js": () => import("./../../../src/pages/about-health-living-phamracy.js" /* webpackChunkName: "component---src-pages-about-health-living-phamracy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-refill-your-rx-js": () => import("./../../../src/pages/refill-your-rx.js" /* webpackChunkName: "component---src-pages-refill-your-rx-js" */),
  "component---src-pages-transfer-rx-js": () => import("./../../../src/pages/transfer-rx.js" /* webpackChunkName: "component---src-pages-transfer-rx-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-about-dr-js": () => import("./../../../src/templates/about-dr.js" /* webpackChunkName: "component---src-templates-about-dr-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

